import React from 'react';

export default function TabContent() {
  return(
  	<React.Fragment>
  		<div className="tab-content w75prct whitepaper-content">
        <div id="paving-the-future-of-entertainment" className="tab-pane fade in active">
          <h3>Paving the Future of Entertainment</h3>
          <p>
            There was a time, not so long ago, when artists performed on stage, streets, and theatres.
          </p>
          <p>
            But that was the past. In the next wave of the future, a new breed of artists have emerged to take their art to places beyond the obvious.
          </p>
          <p>
            Digital has emerged as the new frontier for arts and entertainment - it’s a new medium where the show can go on without any boundaries, restrictions, or limitations.
          </p>
          <p>
            Technology has enabled artists and audiences to come together for richer, more personalized, and highly immersive entertainment experiences.
          </p>
          <p>
            From virtual art galleries and online opera shows to personalized DJ sets and live-streaming music concerts, artists continue to harness the possibilities of technology as a tool for creative self-expression and virtual collaboration.
          </p>
          <p>
            Today, thousands of fans and supporters have turned to AR, VR, and Metaverse communities to experience their favorite artists in their unique creative glory.
          </p>
          <p>
            Tomorrow, millions more will join the revolution and embrace the future of digital arts and virtual entertainment.
          </p>
        </div>
        <div id="the-superstar-fam" className="tab-pane fade in">
          <h3>The Superstar Fam</h3>
          <p>
            The Superstar Fam (TSF) was conceived in 2021 with a vision to enable the future generations of artists and entertainers to express, share and engage with their superfans, all around the world.
          </p>
          <p>
            We are a blockchain entertainment company with a mission to unite artists and audiences from around the globe in an open, inviting, and rewarding space.
          </p>
          <p>
            As performing arts and entertainment continue to become digital-first, with TSF, we want to become the chosen platform where artists, entertainers, and fans come together to hang out, have fun and connect with opportunities to earn.
          </p>
          <p>
            TSF is a member-run and community-led space where artists and audiences can coexist in a trustworthy, transparent, and fun way.
          </p>
          <p>
            The NFT space, today, is overcrowded with the same, run-of-the-mill animal character and avatars and the generative art formula has the same creative recipe overdone.
          </p>
          <p>
            To kickstart our community, we are launching a collection of 10102 NFTs that are hand-drawn, unique & diverse - inspired by artists, musicians, dancers, magicians, and entertainers. These digital avatars live on the Ethereum blockchain as ERC-721 tokens and are hosted on IPFS.
          </p>
          <p>
            But that is just the beginning… We are building a utility-driven ecosystem for digital entertainment and have a long-term, impactful, sustainable, and profitable vision for the community.
          </p>
        </div>
        <div id="our-vision" className="tab-pane fade in">
          <h3>Our Vision</h3>
          <p>
            There is a huge opportunity for artists and entertainers to find new ways to grow and sustain their creative pursuits.
          </p>
          <p>
            With TSF, we are building a La La land in the Metaverse where entertainment and opportunity comes together for creators and supporters.
          </p>
          <p>
            By owning TSF NFTs, collectors can become members of a select club called the La La Land and enjoy exclusive events, rewards, passes, and unique opportunities to earn and have fun.
          </p>
          <p>
            We are powering a blockchain-based artist-fan economy that is fairer, more transparent, and more sustainable than anything that’s going on today IRL. We are enablers and facilitators, not mediators or gatekeepers of the community.
          </p>
          <p>
            We are helping artists sell anything on our platform - to start off with shows, concerts, live gigs, and performances and eventually move to merchandise, collectibles, art, pictures, and experiences. This way rising artists can not only find an audience, but also open doors for sustainable revenue generation and creative self-expression.
          </p>
          <p>
            Our objective is to become the go-to platform for global artists to connect with audiences and find new opportunities to earn and entertain.
          </p>
          <p>
            In our metaverse, we are giving artists and audiences the keys to their own kingdoms.
          </p>
        </div>
        <div id="community-roadmap" className="tab-pane fade in">
          <h3>Community Roadmap</h3>
          <h4>10%</h4>
          <ul className="roadmap">
            <li>
              It’s raining Superstars: 10 reserved Superstar NFTs are airdropped to random holders!
            </li>
          </ul>
          <h4>20%</h4>
          <ul className="roadmap">
            <li>
              Community wallet gets initiated with 10 eth and gets topped up with 5 eth at every 10% sales increment.
            </li>
          </ul>
          <h4>30%</h4>
          <ul className="roadmap">
            <li>
              The first La La Land Show is organized
            </li>
            <li>
              A rising music superstar takes the stage
            </li>
          </ul>
          <h4>50%</h4>
          <ul className="roadmap">
            <li>
              Secret Companion NFT project is initiated. Watch this space!
            </li>
          </ul>
          <h4>60%</h4>
          <ul className="roadmap">
            <li>
              Member-exclusive TSF Merch Store gets unlocked
            </li>
          </ul>
          <h4>70%</h4>
          <ul className="roadmap">
            <li>
              The second La La Land Show goes live!
            </li>
            <li>
              A rising comedian superstar takes the stage
            </li>
          </ul>
          <h4>80%</h4>
          <ul className="roadmap">
            <li>
              Liquidity pool is set up
            </li>
          </ul>
          <h4>90%</h4>
          <ul className="roadmap">
            <li>
              The Superstar Fam’s promised land of dreams opens up: The La La Land will become a reality in the Metaverse
            </li>
          </ul>
          <h4>100%</h4>
          <ul className="roadmap">
            <li>
              The La La Land Show is officially launched as a DAO that’s run and led by the community
            </li>
          </ul>
        </div>
        <div id="tech-bits" className="tab-pane fade in">
          <h3>The Tech Bits</h3>
          <h4>Solidity version : 0.8.0</h4>
          <p>
            We have used OpenZeppelin open source libraries and have ensured best practices are followed.
          </p>
          <p>
            We made sure the code is simple and handles all edge cases yet. After all, engineering is all about keeping it simple. No overhead calls to functions that may consume more gas neither unnecessary use of storage space. (Gas fees avoidance/optimization)
          </p>
          <p>
            Verified Contract: <a target="_blank" href="https://etherscan.io/address/0x953f2e39174debf031dc42d176513f6ee0a48a8c#code">https://etherscan.io/address/0x953f2e39174debf031dc42d176513f6ee0a48a8c#code</a>
          </p>
          <p>
            To create a smooth minting experience and handle higher footfall, we have used super scalable services like Cloudfront and cloud storages. They cache and store the pages over different localized edges (server locations) globally resulting in faster user experience.
          </p>
          <p>
            Our web uses the latest version of libraries to support EIP-1559 and carefully passes priority fees to the miner, just enough for the transaction to be processed within time.
          </p>
          <p>
            <span className="font-bold">“It won’t be fair to put in all this effort if the web goes down during the public sale!!”</span> - and so we have added DDOS protection to our website to guard against the bots and to discourage phishing scams during the public sale.
          </p>
          <p>
            To delegate control to the user we have enabled them to claim their gifts rather than being airdropped by the contract owner. This conforms with the idea of fair distribution among the buyers.
          </p>
          <p>
            All NFTs are stored over IPFS and their provenance would be available on chain before the reveal itself. Metadata would also be published to IPFS to guarantee immutability.
          </p>
          <h3>NFT creation process</h3>
          <p>
            Each trait has been assigned a weight and fed into the script. The script shuffles each occurrence of the trait and forms an NFT character. This is in such a manner that it will result in a different combination each time it is run. We would be using a unique string to work as seed for the randomisation of the traits.
          </p>
          <p>
            We have used over 300+ hand drawn traits to generate the collection where the artists have put their creative brains to uniquely conceptualize each trait.
          </p>
        </div>
        <div id="landscape" className="tab-pane fade in">
          <h3>Landscape</h3>
          <h4>Creator Economy</h4>
          <p>
            Since the pandemic, the creator economy has further received a massive boost, as more people are consuming digital content across devices.
          </p>
          <p>
            This has led to a record investment of $1.3B in funding in 2021 alone. From vloggers and musicians to artists and entertainers, more self-employed individuals are making money off of their specific knowledge and following. As of 2021, the entire creator economy is estimated at a whopping $100 billion.
          </p>
          <p>
            Web 2.0 platforms like YouTube, TikTok, Substack, Twitch, OnlyFans, Instagram, and the likes have helped millions of creators to monetize their skills and talent through their channels.
          </p>
          <p>
            However, big tech platforms have faced backlash from creators and artists due to their skewed policies, strict regulations, increasing commissions, and rampant privacy measures.
          </p>
          <p>
            Traditional companies are known for wanting a piece of creators’ paycheck, and in recent years, these centralized entities have been exercising greater control over revenue and distribution of creative assets on their network.
          </p>
          <p>
            Web 3.0 takes the creator economy to the next level and gives creators control of their creative destiny. With Web 3.0, creators and artists are more empowered than they’ve ever been in the history of the industrial and technological revolution.
          </p>
          <p>
            To quote Jack Butcher, the cycle of the creator economy has progressed this way:
          </p>
          <h4>Web1:</h4>
          <ul>
            <li>Companies create content</li>
            <li>Companies earn money</li>
          </ul>

          <h4>Web 2:</h4>
          <ul>
            <li>People create content</li>
            <li>Companies earn money</li>
          </ul>

          <h4>Web 3:</h4>
          <ul>
            <li>People create content</li>
            <li>People earn money</li>
          </ul>
          <br></br>
          <p>
            Creators want an escape route from the centralized corporations and their regulations. With Web 3.0, creators can win back the leverage to create, sell and share their talents without any middlemen or gatekeepers.
          </p>
          <p>
            The DAO (Decentralized Autonomous Organization) wave is helping them with the power of decentralized &, open networks. DAOs have emerged as a flexible, transparent, and open symbol for the creator-supporter relationship.
          </p>
        </div>
        <div id="opportunity" className="tab-pane fade in">
          <h3>Opportunity</h3>
          <h4>1. NFTs as a token of commerce</h4>
          <p>
            From a $41 million in traded volume in 2018 to an unprecedented $400 million by March 2021 (<a target="_blank" href="https://www.binance.com/en/blog/421499824684902131/how-nfts-are-changing-the-creator-economy">source</a>), the NFT space has become increasingly attractive for creators, artists, and entertainers.
          </p>
          <p>
            Today, NFTs represent a booming market, with the trading volume estimated at $2 billion in Q1 2021 (<a target="_blank" href="https://www.cnbc.com/2021/04/13/nft-sales-top-2-billion-in-first-quarter-with-interest-from-newcomers.html">source</a>), more than 20 times the volume of the previous quarter. What’s amazing is that out of this, USD 1.5$ Billion worth of trading volume happened within a span of a month.
          </p>
          <p>
            Some notable projects include Cryptokitties, CryptoPunks, Bored Apes Yacht Club, Gutter Cat Gang, On1Force, Lazy Lions, ArtBlocks, etc
          </p>
          <p>
            Dapper Labs, who recently raised a $250 million fund to build a fan-friendly NFT platform, has partnered with La Liga to launch a digital collection of football videos as NFTs for fans of superclubs like Real Madrid and FC Barcelona.
          </p>
          <p>
            They have also joined with the NBA to create and distribute limited edition sport collectibles and official merchandise on the blockchain.
          </p>
          <p>
            NFTs are a confluence of arts and technology, and they have now emerged as a globally accepted medium (or token) for commerce.
          </p>
          <p>
            Firstly, as they are non-fungible by design, each token is unique and a buyer/holder has complete, exclusive ownership over the digital artwork. NFTs make it possible to fully verify the rights of digital art via a public ledger that cannot be changed or manipulated, therefore eliminating the possibilities of fake or repurposed possession - something that was previously impossible.
          </p>
          <p>
            If an NFT holder wants to sell off an artwork, they first need to verify the rights to reproduce or repurpose an artwork. As a result, the exclusivity and originality aspect of art is not compromised.
          </p>
          <p>
            Secondly, before NFTs, artists had limited control over their creations, and they had to rely mostly on others to find prospective buyers or patrons for their artwork. Further, there were multiple layers of intervention by galleries, agents, traders who wanted to cash in their share from every transaction. With the ability to mint ownership of digital artwork, NFTs have changed how artists make a living, promote their creations and engage with audiences.
          </p>
          <p>
            Thus, NFTs help digital artists to build direct relationships with audiences and art lovers, and helps art lovers establish ownership and signal exclusivity of their purchase via open, decentralized networks like blockchain.
          </p>
          <h4>2. Immersive Games, Virtual Shows and rise of the Metaverse</h4>
          <p>
            The pandemic showed artists and concert-goers that there are alternative ways to appreciate entertainment and theatrics - virtually.
          </p>
          <p>
            A host of global superstars, including - Bruno Mars, Justin Bieber, Post Malone, Dua Lipa, DJ Marshmello, Gorillaz, among others who experimented with live-streamed concerts in 2020 itself.
          </p>
          <p>
            In April 2020, Travis Scott performed in the video game, Fortnite to just under 30 million people. Artists have started to realize a new connection between themselves and their fans. They realized that true presence is between artists and fans.

            In Aug 2021, Kanye West introduced his new album to the world via a mass listening party which was live-streamed by Apple music.
          </p>
          <p>
            Zoom events, live-stream sessions, multiplayer games and small to medium scale online shows are all signifiers to a digital-first and online-only arts and entertainment revolution which is already here and happening in its infancy.
          </p>
          <p>
            There’s a simple but significant reason here - artists and entertainers of all kinds have realized the infinite potential and reach of online platforms, and the massive opportunity that exists across the globe where anyone with an internet connection can become a potential fan, supporter or patron of arts.
          </p>
          <p>
            The ability to reach 100 times as many people in an interactive way is a natural extension of live performances. With in-person and offline events, that scale is impossible to reach, no matter how famous or popular an artist or performer is.
          </p>
          <p>
            We are at a cusp of something revolutionary, both in terms of the technology at play and the creative expression at display, and collectively, this path-breaking phenomenon of the future is what we know today as the Metaverse, and it is taking online events and digital shows to the next level of immersion and entertainment.
          </p>
          <p>
            The future of live events begins in the metaverse - where artists perform in front of thousands and millions of audiences tuned via VR, AR and online-only platforms from all across the world. This enables artists to go beyond the realms of live streaming and build deeper connections with their fans via interactive mediums.
          </p>
          <p>
            Very recently, we have seen mass scale events being held at some notable Metaverses - Decentraland, Cryptovoxels, Somnium Space and The Sandbox. These are not just entertainment venues, but immersive online entertainment environments where NFT owners get a chance to own a piece of virtual land, connect with their favorite artists and entertainers, and find like-minded groups to hang around with. From virtual shows to fests, one can expect several global festivals to go digital-only in the years to come.
          </p>
          <p>
            Even traditional entertainment venues and promoters are embracing the metaverse as the next frontier for live shows and performances. Ibiza’s famous superclub, called Amnesia, recently partnered with Decentral Games to launch their own virtual nightclub where members can enjoy real-time events and catch their favorite electronic artists on the decentralized Ethereum network.
          </p>
          <p>
            The audiences can also enjoy additional club offerings such as virtual casinos, digital art galleries and online auction houses. Notably, world’s biggest video game maker Atari, is already planning to launch its own virtual world based on the blockchain.

            Big money is already flowing into the metaverse, as investors want to cash into the next wave of digital entertainment where virtual spaces will be the first choice for users and fans.
          </p>
          <p>
            Interestingly, Jack Ma, the Chinese investor and entrepreneur de force, recently co-led a funding round of over $100 million in NReal, an AR technology company which enables users to interact in virtual environments via their flagship glasses and apps. In China alone, the AR/VR market alone is expected to grow at 68% over the next five years, forecasted to reach <a target="_blank" href="https://supchina.com/2021/09/24/chinese-investors-pile-into-metaverse-despite-official-warnings/">$300 billion by 2024</a> (despite the fact that the government has warned investors to avoid augmented and virtual reality products).
          </p>
          <p>
            With the metaverse becoming the most important shift in the future of entertainment, that’s where the investor attention and capital is going to flow in the upcoming years. Poised to reach a valuation of $800 billion by 2024, many investors, including Jen -Hsun Huang, the CEO and co-founder of Nvidia,believe that the metaverse economy is going to be much larger than the physical economy
          </p>
          <p>
            The growth and potential for the metaverse is primed to stack in favor of the early supporters, and the likes of Visa, Hyundai, Ralph Lauren and Facebook are some of the popular names leading the wave.
          </p>
          <h4>3. Web 2.0 vs Web 3.0: The Economics of Creator Networks</h4>
          <p>
            NFTs go beyond just being uniquely identifiable, virtual tokens for digital art. They are now redefining the value of arts and creativity and how entertainment is owned and distributed. Decentralized networks on the blockchain have already shown how open source is a better value proposition for both artists and their fans.
          </p>
          <p>
            During the 80s and 90s, digital entertainment first became mainstream when cable television emerged as the mass medium of communication. It soon led to the era of iTunes where one could purchase their favorite music and support their favorite artists by the click of a button. All this while, CDs and Vinyls continued to exist (and even grow) as another way for fans to own their music.
          </p>
          <p>
            Fast forward to today, streaming services like Spotify, Apple Music and Tidal have emerged as the chosen destination for millions of users worldwide to discover and listen to music. The consumers can easily access music by paying a subscription service fee to these platforms, who in turn, pay the artists whenever someone ‘streams’ a song in their app.
          </p>
          <p>
            Thus, access has been replaced by ownership. Majority of music lovers no longer want to own an album or a vinyl record, when they can easily open an app and listen to their favorite songs on a tap with a small recurring fee.
          </p>
          <p>
            Music streaming now accounts for 83% of the industry’s entire revenue. While this model works well for the tech platforms and casual listeners, it comes at the cost of the artists who are getting squeezed harder than ever for every buck.
          </p>
          <p>
            Typically, a streaming service giant like Spotify pays an artist about $0.003 per stream, which comes down to about $10 for 10000 streams.

            This pro-rata revenue model which is based on the popularity of an artist basically ensures that only the top 10% continue to get richer due to 90% people streaming popular music , while the majority of the lesser-known musicians and emerging artists are left to struggle as they cannot generate enough ‘streams per month’.
          </p>
          <p>
            Talk about the value of blood, sweat and tears that goes into creating art. The economics of how talent is being valued today by large tech companies and platforms is not just skewed, it’s massively unfair to the artists and gives them back very little financially.
          </p>
          <p>
            NFTs have the potential to disrupt the traditional model, and it has already emerged as the chosen medium for musicians who want to take control of their creative fate. Some notable artists who have tested the waters include - 3LAU, an EDM musician who sold $11 million by selling an album of 33 individual NFTs, Mike Shinoda of Linkin Park who sold his art via NFT marketplace, Steve Aoki, the superstar DJ, sold his collection for $4.25 million via digital markets. Even legacy bands like Kings of Leon have decided to sell their latest album as an NFT collection, which generated $2 million in sales for them.

            NFTs represent a new and fair way of ascribing value to what was previously undervalued and underappreciated. What was previously bound by high commissions, greedy intermediaries, unfair remuneration and limited exposure is now turning into new opportunities for artists who want to continue doing what they love, while earning decently and not losing money to corporations and big tech platforms.
          </p>
          <p>
            The music and entertainment industry is filled with countless examples of bad deals hurting talent and even suppressing creativity in general. The decentralized economy is turning this upside down and finally enabling artists to charge a fair price for their creative talent via cryptocurrencies.
          </p>
          <p>
            In addition to earnings, artists are able to take control of their IP with NFTs. Traditionally, record labels and publishers own the copyrights to a song or piece of music, thereby gaining complete access over the distribution and sales. This way, record labels control, collect and distribute the copyrights while giving a portion of the earnings to the original artists or musician..
          </p>
          <p>
            This portion is a small fraction of the total earnings, further as artists do not own the copyright to their creations, it limits the money they can make in royalties, therefore leading to wasted earning potential in unclaimed or disputed royalties.
          </p>
          <p>
            NFTs are reshaping the way an artist controls their IP and monetizes their creative talents.
          </p>
          <p>
            When an artist sells their creations as an NFT, they give the right to claim original ownership of the NFT to a buyer, and may also choose to give the permission to further distribute the IP.
          </p>
          <p>
            Thus, in a decentralized world, the author or the creator owns the copyright to their work and they have complete freedom and control over transferring complete or partial ownership of the IP to someone else. As the transactions happen on a public through a non-fungible token, the artist is able to easily track wherever their IP is being published or promoted without their permission.
          </p>
          <p>
            This is already helping thousands of artists take creative control of their craft and capitalize without having to rely on distributors, promoters or record labels.
          </p>
          <h4>4. From supporters to superfans</h4>
          <p>
            For fans, NFTs are virtual relics of a limited-edition merchandise, or passes to an exclusive online event, the ownership of which works as a collector’s item.
          </p>
          <p>
            In the current model, fans have to go through an intermediary, or a platform or service provider to connect with their favorite artists. Whether it’s a concert ticket or a live show, when a fan backs an artist through a third-party layer in between, a lot of support is lost in translation.
          </p>
          <p>
            Record and ticket sales, merchandise revenues, resale royalties - with NFTs, artists no longer have to share a cut of their earnings with someone else.
          </p>
          <p>
            NFTs allow fans to remove the bridge between them and their favorite artists. Through decentralized platforms, fans no longer have to go through a regulatory platform or an intermediary service provider, they can lend their support and backing for an artist directly.
          </p>
          <p>
            This creates a deeper relationship between artists and their audiences. When the support comes from a superfan, instead from a third-party vendor or promoter, it has a deeper meaning, whether to an artist trying to make their mark for the first time or doing sold-out concerts for the nth time.
          </p>
          <p>
            For an independent artist, the ability to connect with their fans and supporters could be the difference between churning out creative work versus going back to doing something they are not meant to do.
          </p>
          <p>
            With NFTs, third-parties and middlemen are no longer an essential part of the distribution and promotion equation, and it’s the artist-fan relationship which self-sustains on the blockchain, giving way to immersive ways to interact, engage and entertain, while fostering a creatively rewarding and financially sustainable symbiotic relationship devoid of any conditions.
          </p>
          <p>
            Blockchain levels the playing field, and enables every supporter to become a super fan and take active part in charting the success story of their favorite artists and creators.
          </p>
        </div>
        <div id="investment-consideration" className="tab-pane fade in">
          <h3>Investment Consideration</h3>
          <p>
            Virtual-reality-based entertainment platforms are a lucrative investment space for HNW individuals and businesses ready to join in the next wave of hypergrowth in digital entertainment and interactive events.
          </p>
          <p>
            Here’s what makes TSF a valuable entry point in the NFT space:
          </p>
          <p>
            Everybody knows how Web 1.0 and Web 2.0 helped early backers rake in massive profits and monumental returns on investment.
          </p>
          <p>
            Now Web 3.0, while still in its infancy, is already on the track to become the most attractive and lucrative investment opportunity for early-stage investors and high net worth individuals who are looking at an incredible upside potential within the next decade.
          </p>
          <p>
            The global crypto users surpassed 200 million in 2021, and the pace of adoption from a 100 million to its double took just four months.
          </p>
          <p>
            In Decentraland, a virtual reality platform for NFTs, monthly sales volume reached $4M in asset value in June from $1M in January 2021.
          </p>
          <p>
            According to Mark Zuckerberg, CEO of Facebook, the metaverse is already poised to become the successor of the internet.
          </p>
          <p>
            There's real opportunity and earning potential in the metaverse, and here's why TSF is a promising investment for investors ready to capitalize on the opportunity:
          </p>
          <h4>1. Personality Driven Artwork</h4>
          <p>
            The NFT space, today, is overcrowded with the same, run-of-the-mill animal character and avatars.
          </p>
          <p>
            Lions, apes, bulls, bears - the generative art formula is the same, and the creative recipe, already overdone.
          </p>
          <p>
            TSF challenges the status quo with its unique, diverse and <span className="font-bold">inclusive</span> artwork. We are one of the first NFT projects that has non-binary representation within their generative artwork. We are gender-fluid and bring diverse personas to be a part of one open stage. Because when there is equal opportunity for everyone, we all rise and shine together. We believe in bringing people together, in being open, fair, irrespective of gender or background. When it comes to breaking boundaries related to gender, The Superstar Fam is here to take the leap.
          </p>
          <p>
            Inspired by artists and entertainers from different walks of life, our collection of 10k+ hand-drawn characters features musicians, dancers, painters, magicians, comedians among others.
          </p>
          <p>
            We wanted to give our collection an emotional and human connection, and our vision is successfully reflected in the character design.
          </p>
          <p>
            Further, our first NFT project isn’t a half-baked effort with just a top-only design, TSF is a full-blown, completely fleshed NFT design which is personality-driven from head to toe.
          </p>
          <p>
            In TSF metaverse, each NFT character has a story, a well-thought concept behind their existence, therefore making all of them relatable, whilst giving each of the characters a distinctive, personal appeal.
          </p>
          <p>
            The limited supply of the TSF NFTs capped at 10102 units, in combination with their universal appeal positions them as a highly sought-after digital token.
          </p>
          <h4>2. Utility-First Collection</h4>
          <p>
            To make our first NFT collection truly rare, we’ve capped the art at 10102 characters only.
          </p>
          <p>
            TSF artwork is a one-of-a-kind, once-in-a-lifetime opportunity for NFT collectors and generative art lovers.
          </p>
          <p>
            The TSF NFT is just the first step towards building a utility-driven ecosystem for digital entertainment, here’s what we have in store as part of our long-term creative and commercial vision for the community:
          </p>
          <ul style={{listStyle: "lower-alpha"}}>
            <li>
              Live-Shows and Concerts: Every TSF NFT holder gets an entry pass to La La Land - a virtual space where artists and entertainers come together to perform live
            </li>
            <li>
              Limited Edition merchandise: Emerging artists and popular entertainers distribute exclusive merch for fans and event attendees in limited quantities.
            </li>
            <li>
              PlusOne passes: A virtual ticket for every event hosted in La La Land. It doubles up as a promoter’s pass, through which TSF NFT holders can earn profit by selling.
            </li>
            <li>
              Signature NFTs: Insignia created in collaboration with artists and released as part of live performances, which can be owned by members for unlocking long-term gains.
            </li>
          </ul>
          <br></br>
          <p>
            <h4>3. Community At The Core</h4>
            To encourage rising artists and emerging creators to join our platform, we need to ensure we get the support and backing needed to be free, both creatively and financially.
          </p>
          <p>
            For this, we have built our engagement model on a fan-first approach. Here’s how we plan to nurture the artist-fan relationship:
          </p>
          <ul style={{ listStyle: "number"}}>
            <li>
              <span className="font-bold">Rewarding early supporters:</span> select members who have backed the community as early supporters will get access to exclusive raffles.
            </li>
            <li>
              <span className="font-bold">Building artists’ funds:</span> As a part of the roadmap, by the end of 100% of the sales, 50 ETH will be contributed to the Artists’ Funds. Moreover, with every TSF NFT purchase in the secondary market and every PlusOne pass sold, a small share of the value is contributed towards building a pool of funds or a community wallet to support artists.
            </li>
            <li>
              <span className="font-bold">Member-driven roadmap:</span> every NFT holder gets to share the responsibility to shape the events, activities, growth, and vision of the community with their individual and collective ideas.
            </li>
            <li>
              <span className="font-bold">Community-oriented events:</span> members decide their favorite artists and pick emerging creators to give them an opportunity to be a part of the platform. Events cater to a diverse mix of entertainers like musicians, comedians, dancers, DJs, etc.
            </li>
          </ul>
          <br></br>
          <p>
            <h4>4. Self- Sustainable model</h4>
            TSF is a by-fans, for-fans community where music and entertainment enthusiasts can take active participation in supporting talented and rising artists early on in their careers.
          </p>
          <p>
            The TSF DAO will allow fans to vote and support their favorite artists, which in turn, will help emerging as well as established creators gain a larger fan following via word-of-mouth and a bigger reach in the entertainment metaverse.
          </p>
          <p>
            The higher the reach gets for an artist, the more fans they can amass and attract to their events in the TSF metaverse. As new artists join the platform, increasing competitiveness will fuel more fans to become members of the community.
          </p>
          <p>
            More fans will lead to a higher number of events on the platform, and in turn, drive higher volumes of event-specific passes and artists-specific merchandise sales.
          </p>
          <p>
            Thus, value is created via network growth and the entire DAO works on a self-feeding and self-serving operating principle.
          </p>
          <p>
            With a self-sustainable approach, we envision TSF as a full-bloomed community that grows and matures into a digital entertainment hub where the NFT holders or fans enjoy immersive events and shows, and artists reap the benefits of the platform’s continual growth.
          </p>
        </div>
        <div id="tsf-team" className="tab-pane fade in">
          <h3>The Superstar Fam Team</h3>
          <p>
            The cryptocurrency space is built on the principle of privacy and anonymity.
          </p>
          <p>
            This makes it easier and accessible for anyone to be a part of an open future, but it also creates a general lack of ownership and pseudo projects built to mislead and/or derive value from malign practices.
          </p>
          <p>
            At TSF, we want to lead by example by being fully non-anonymous project leaders, ready to establish a positive reputation in the NFT community with honest artwork, transparent product roadmap and a harmonious vision to build a future entertainment platform where artists and entertainers are rewarded fairly for their creative talents.
          </p>
          <p>
            We started TSF with a small, tightly-knit family of just two individuals ready to challenge the status quo, and soon grew into a family of 8 high-integrity individuals with a genuine passion to do our bit for the artists community.
          </p>
          <h3>Founding Members and Core Team</h3>
          <p>
            <a target="_blank" href="https://twitter.com/aishakadon">@Don</a> - Co-Founder, Generalist
          </p>
          <p>
            A dreamer, an optimist, and a workaholic!
          </p>
          <p>
            Don wants to create a long-term impact with TSF & is passionate about empowering artists in the NFT space.
          </p>
          <p>
            <a target="_blank" href="https://twitter.com/zankalp">@Seven</a> - Co-Founder, Operations
          </p>
          <p>
            An active investor in the crypto space with a passion for design!
          </p>
          <p>
            When not thinking in 1s and 0s, he likes to daydream about how blockchain will change the world for the better.
          </p>
          <p>
            <a target="_blank" href="https://twitter.com/paraskirun">@ParaSkiRun</a> - Technology
          </p>
          <p>
            Our in-house technologist
          </p>
          <p>
            When not writing code, he likes to seek adventure!
          </p>
          <p>
            He's fond of online communities & is now taking his first steps into building one with TSF
          </p>
          <p>
            <a target="_blank" href="https://twitter.com/freek1ll_">@Freekill</a> - Community
          </p>
          <p>
            He leads the community building efforts and makes sure everyone is given a fair and open stage to discuss, entertain and have a good time.
          </p>
          <p>
            He's excited to be a part of the NFT space & can't wait to build fast and shake things
          </p>
          <p>
            <a target="_blank" href="https://twitter.com/boynamedbharat">@Brat</a> - Marketing
          </p>
          <p>
            A writer with a passion for marketing and everything storytelling.
          </p>
          <p>
            He leads the buzz and brand building activities at TSF and makes sure everyone gets to hear about the vision and mission of La La Land in its glory!
          </p>
          <h3>Design Team</h3>
          <p>
            To walk the talk, we decided to work with up and coming artists who helped create our distinctive and amazing artwork of 10k+ superstar NFTs.
          </p>
          <p>
            We have 3 designers on our team. We had to stay true in our mission to empower upcoming & rising artists so all 3 are just starting off their careers in art with NFTs as the new frontier.
          </p>
          <p>
            <a target="_blank" href="https://www.instagram.com/sara_bharadwaj">@SaraBhardwaj</a> - Lead Designer
          </p>
          <p>
            An artist at heart, Sara is the creative powerhouse behind our amazing and diverse collection of NFT artists.
          </p>
          <p>
            She’s excited to make a dent in the NFT space with her talents.
          </p>
          <p>
            <a href="https://www.behance.net/tushargaur1">@Tushar</a> - Lead Designer
          </p>
          <p>
            A rising superstar designer with an unmatched creative flair
          </p>
          <p>
            Tushar is pumped to be a part of his first NFT project with TSF and is now looking forward to creating more cool stuff for the community.
          </p>
          <p>
            <a target="_blank" href="https://www.instagram.com/ramnika.sehrawat/">@Ramnika</a> - Lead Designer
          </p>
          <p>
            A design junkie at heart, likes to get high on art.
          </p>
          <p>
            Ramneeka loves being a part of an open and inviting creative space like TSF, and she likes contributing her creative talents towards building an art-first global community of designers, artists and painters.
          </p>
          <hr></hr>
          <p>
            The characters have been hand-designed to the last detail by emerging talent who are just starting off their careers and needed an opportunity to show what they're capable of.
          </p>
          <p>
            As it turned out, they're now a part of our extended family and have received strong validation and praise for their talents from the NFT community.
          </p>
          <p>
            At TSF, we want to put our proud foot forward and show the NFT community that an effective team is the one where diversity is at the center and where every member gets the opportunity to do their best work.
          </p>
        </div>
      </div>
     </React.Fragment>
  )
}
