import React from 'react';
import NavBar from './Navbar';
import TabNav from './TabNav';
import TabContent from './TabContent';

export default function whitePaper() {
  return(
    <React.Fragment>
      <NavBar/>
      <div className="container">
        <div className="flex pt80 h100 xs-flexColumn">        
          <TabNav />         
          <TabContent />
        </div>
      </div>
    </React.Fragment>
  );
}
