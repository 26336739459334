import React from 'react';

export default function TabNav() {
  return(
  	<React.Fragment>
  		<ul className="nav nav-pills flex flexColumn w25 sidebar-tab">
        <li class="active"><a data-toggle="pill" href="#paving-the-future-of-entertainment">Future of Entertainment</a></li>
        <li><a data-toggle="pill" href="#the-superstar-fam">The Superstar Fam</a></li>
        <li><a data-toggle="pill" href="#our-vision">Our Vision</a></li>
        <li><a data-toggle="pill" href="#community-roadmap">Community Roadmap</a></li>
        <li><a data-toggle="pill" href="#tech-bits">The Tech Bits</a></li>
        <li><a data-toggle="pill" href="#landscape">Landscape</a></li>
        <li><a data-toggle="pill" href="#opportunity">Opportunity</a></li>
        <li><a data-toggle="pill" href="#investment-consideration">Investment Consideration</a></li>
        <li><a data-toggle="pill" href="#tsf-team">The Superstar Fam Team</a></li>
      </ul>
     </React.Fragment>
  )
}
