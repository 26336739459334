import React from "react";
import ReactDOM from "react-dom";
import App from "./App"
// Import DAppProvider
import { DAppProvider } from "@usedapp/core";
// import './components/navbar/main.less';

ReactDOM.render(
  <React.StrictMode>
    {/* 
       Wrap our app in the provider, config is required, 
        but can be left as an empty object: 
    */}
    <DAppProvider config={{}}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);