import Web3 from "web3";
import { useState, useEffect } from "react";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { ADDRESS, ABI } from "../config";

export default function GiftPage() {
  const MAX_SUPERSTATS_MINTABLE = 20;
  const MIN_SUPERSTARS_MINTABLE = 1;
  // const singedIn = (localStorage.getItem("signedIn") === 'true');

  const [maxSuperstarMintable, setMaxSuperstarMintable] = useState(20);
  const [isGiftActive, setIsGiftActive] = useState(true);

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [ethBrowser, setEthBrowser] = useState(true);
  const [message, setMessage] = useState(null);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [link, setLink] = useState(null);
  const [hasMinted, setHasMinted] = useState(0);
  const [alloted, setAlloted] = useState(0);

  // FOR YOUR WALLET
  const [signedIn, setSignedIn] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [network, setNetwork] = useState(null);

  // FOR MINTING
  const [buyingSuperStarsCount, setBuyingSuperStarsCount] = useState(
    MIN_SUPERSTARS_MINTABLE
  );
  const [contract, setContract] = useState(null);

  // INFO FROM SMART Contract
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 10000);
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      setLink(null);
    }, 10000);
  }, [link]);

  const updateSuperStarDetails = () => {
    if (!window.signedIn) {
      return;
    }
    const contract = new window.web3.eth.Contract(ABI, ADDRESS);
    updateContractDetails(contract);
  };

  async function signIn() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    window.web3.eth.net
      .getNetworkType()
      // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
      .then((network) => {
        // console.log(network);
        setNetwork(network);
      });

    if (typeof window.web3 !== "undefined") {
      // Use existing gateway
      window.web3 = new Web3(window.ethereum);
      setIsLoading(true);
    } else {
      alert("No Ethereum interface injected into browser. Read-only access");
    }

    window.ethereum
      .enable()
      .then(function (accounts) {
        let wallet = accounts[0];
        window.walletAddress = wallet;
        setWalletAddress(wallet);
        setSignedIn(true);
        window.signedIn = true;
        localStorage.setItem("signedIn", true);
        callContractData(wallet);
        setIsLoading(false);
      })
      .catch(function (error) {
        // Handle error. Likely the user rejected the login
        console.error(error);
        setIsLoading(false);
      });

    // For Logout, when user explicitly disconnects
    window.ethereum.on("accountsChanged", function (payload) {
      if (payload[0] === undefined) {
        window.walletAddress = null;
        setWalletAddress(null);
        setSignedIn(false);
        window.signedIn = false;
        localStorage.setItem("signedIn", false);
        setIsGiftActive(false);
      }
    });
  }

  async function signOut() {
    localStorage.setItem("signedIn", false);
    window.signedIn = false;
    setSignedIn(false);
  }

  async function callContractData(wallet) {
    // let balance = await web3.eth.getBalance(wallet);
    // setWalletBalance(balance)
    const contract = new window.web3.eth.Contract(ABI, ADDRESS);
    setContract(contract);
    updateContractDetails(contract);
  }

  const updateContractDetails = async (contract) => {
    const isGiftActive = await contract.methods.claimGiftActive().call();
    setIsGiftActive(isGiftActive);

    if (window.walletAddress) {
      const { allotted, hasMinted } = await contract.methods
        .giftlist(window.walletAddress)
        .call();
      setAlloted(parseInt(allotted));
      setHasMinted(parseInt(hasMinted));
      let mintableSuperStar = isGiftActive
        ? parseInt(allotted) - parseInt(hasMinted)
        : 0;
      setMaxSuperstarMintable(mintableSuperStar);
      if (isGiftActive && parseInt(allotted) - parseInt(hasMinted) == 0) {
        setBuyingSuperStarsCount(0);
      }
    }
  };

  const pluralize = (qty, str) => {
    return `${str}${parseInt(qty) > 1 ? "s" : ""}`;
  };

  async function mintsuperStar(buyingSuperStarsCount) {
    if (contract) {
      setIsLoading(true);
      const price = 0;

      // Add gifting event to be captured.
      contract.events.Gift({}).on("data", async function (event) {
        console.log(event.returnValues);
        if (event.returnValues.owner.toLowerCase() === window.walletAddress) {
          setMessage(
            `Successfully bought ${event.returnValues.qty} ${pluralize(
              event.returnValues.qty,
              "Superstar"
            )}`
          );
          setIsErrorMessage(false);
          setBuyingSuperStarsCount(MIN_SUPERSTARS_MINTABLE);
          setIsLoading(false);
          updateContractDetails(contract);
        }
      });

      const gasAmount = await contract.methods
        .claimSuperstarGifts(buyingSuperStarsCount)
        .estimateGas(
          { from: walletAddress, value: price },
          function (error, gas) {
            if (error && error.code === -32000) {
              setMessage("Insufficient Funds");
              setIsErrorMessage(true);
              setIsLoading(false);
            }
          }
        )
        .catch((err) => {
          if (err.message.includes("execution reverted")) {
            let finalMessage = err.message
              .split("execution reverted:")[1]
              .split("\n")[0];
            setMessage(finalMessage);
            setIsErrorMessage(true);
          }
          setIsLoading(false);
        });

      if (!gasAmount) {
        return true;
      }
      contract.methods
        .claimSuperstarGifts(buyingSuperStarsCount)
        .send({ from: walletAddress, value: price, gas: String(gasAmount) })
        .on("transactionHash", function (hash) {
          console.log("transactionHash", hash);
          setLink(`https://etherscan.io/tx/${hash}`);
        })
        .on("error", function (error) {
          if (error && error.code === 4001) {
            setMessage("You have rejected the transaction");
            setIsErrorMessage(true);
            setIsLoading(false);
          }
        });
    } else {
      console.log("Wallet not connected");
    }
  }

  function setSuperStars(value) {
    if (value === "") {
      return setBuyingSuperStarsCount("");
    }
    value = parseInt(value);
    if (isGiftActive && value == 0) {
      setBuyingSuperStarsCount(0);
    } else if (value < MIN_SUPERSTARS_MINTABLE) {
      setBuyingSuperStarsCount(MIN_SUPERSTARS_MINTABLE);
    } else if (value > maxSuperstarMintable) {
      setBuyingSuperStarsCount(maxSuperstarMintable);
    } else {
      setBuyingSuperStarsCount(value);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function walletAddressElement() {
    if (!signedIn && !walletAddress) {
      return <></>;
    }
    return (
      <p
        data-toggle="modal"
        data-target="#exampleModalCenter"
        className="mt10 mb0 f14 text-underline cursor-pointer"
      >
        {walletAddress}
      </p>
    );
  }

  function isGiftListed() {
    return !(alloted === 0 && hasMinted === 0);
  }

  function canMintMore() {
    return hasMinted < alloted;
  }

  function mintingBody() {
    return (
      <>
        {signedIn && network ? (
          <p className="connected">
            <span className="dot"></span>Connected to{" "}
            {capitalizeFirstLetter(network)} Network
          </p>
        ) : (
          <span>
            <p className="text-center" style={{ fontSize: "24px" }}>
                Yes, we have got a Gift for you! <a href="https://discord.gg/QRcSUZfMN8" target="_blank">
                  <svg width="24px" height="20px" viewBox="0 0 71 55" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                      <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="71" height="55" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </p>
            <p className="connected cursor-pointer" onClick={signIn}>
              <span></span>Connect Wallet with Metamask
              <span className="blinking-div"></span>
            </p>
          </span>
        )}
        {signedIn ? (
          <>
            {isGiftActive && (
              <p className="sale-live-text mb0">Claim your Gifts</p>
            )}
            {!isGiftActive && (
              <p className="sale-live-text mb0">Gift claim is not Live</p>
            )}
            <p className="sale-live-text mb0"></p>
            <p className="sale-live-text">
              {buyingSuperStarsCount || 1}{" "}
              {pluralize(buyingSuperStarsCount || 1, "Superstar")} = 0 ETH + GAS
            </p>
            {isGiftActive && (
              <p className="mb20">
              </p>
            )}
            {isGiftActive && <p className="mb0"></p>}
            <div className="plus-minus-wrapper">
              <input
                disabled={!isGiftActive}
                type="number"
                placeholder="1"
                value={buyingSuperStarsCount}
                onChange={(e) => setSuperStars(e.target.value)}
                maxLength="2"
                min={MIN_SUPERSTARS_MINTABLE}
                step="1"
                max={maxSuperstarMintable}
              />
              <span
                onClick={() => setSuperStars(maxSuperstarMintable)}
                className="max"
                style={{ textDecoration: "underline" }}
              >
                {!isGiftActive ? "" : "Max Claim"}
              </span>
              <button
                disabled={
                  !isGiftActive ||
                  (walletAddress && isGiftActive && !canMintMore())
                }
                onClick={() => mintsuperStar(buyingSuperStarsCount)}
              >
                {"Claim"}
              </button>
            </div>
            {walletAddress && isGiftActive && !isGiftListed() && (
              <p>
                Oops! You're not on the GiftList. Join our{" "}
                <a
                  href="https://discord.gg/QRcSUZfMN8"
                  target="_blank"
                  className="eth-link"
                >
                  discord
                </a>{" "}
                to enroll.
              </p>
            )}
            {walletAddress && isGiftActive && isGiftListed() && canMintMore() && (
              <p>
                Available to claim: {alloted - hasMinted}{" "}
                {pluralize(alloted - hasMinted, "Superstar")}
              </p>
            )}
            {walletAddress &&
              isGiftActive &&
              isGiftListed() &&
              !canMintMore() && (
                <p>You have already minted all allocated superstars.</p>
              )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div id="body" className="container-fluid p0">
      <div className="container-fluid navbar-inverse gift-header">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img src="images/purpletransparent.png" width="40" height="40" className="d-inline-block align-top" alt="" />
            <span>The Superstar Fam</span>
          </a>
        </div>
      </div>
      <div className="mint-banner-wrapper relative">
        <img className="img-responsive" src="images/mint-banner.png" alt="" />
        <div className="mint-banner-content">
          {!ethBrowser ? (
            <a
              href="https://metamask.io/download.html"
              target="_blank"
              className="connected cursor-pointer"
            >
              <span></span>Install Metamask Extension
            </a>
          ) : (
            mintingBody()
          )}
        </div>
        {message && (
          <p
            className={isErrorMessage ? "error-mesg" : "error-mesg success-msg"}
          >
            {message} &nbsp;
            <span
              className="cursor-pointer"
              onClick={() => setMessage(null)}
              style={{ textDecoration: "underline" }}
            >
              Close
            </span>
          </p>
        )}
        {!message && link && (
          <p className="error-mesg success-msg">
            Your{" "}
            <a href={link} target="_blank" className="eth-link">
              transaction
            </a>{" "}
            has been submitted and is being processed. &nbsp;
            <span
              className="cursor-pointer"
              onClick={() => setLink(null)}
              style={{ textDecoration: "underline" }}
            >
              Close
            </span>
          </p>
        )}
        {isLoading && (
          <div className="overlay">
            <img className="img-responsive" src="images/loader.gif" alt="" />
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body p0">
              <p>Do you really want to logout?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={signOut}
              >
                Yes, Logout
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
