export default function VideoShow({ setIsPresenting}) {
  return(
    <div className="container-fluid p0 pt60" id="about-us">

      <div className="banner-wrapper">
        <a href="javascript:void(0);" className="start-minting cursor-pointer" onClick={ () => setIsPresenting(false) }>Start Minting</a>
        <video muted autoPlay loop style={{ width: "100%" }}>
          <source src="https://dla1e4xo0vb1n.cloudfront.net/external_media/driveincinemavid.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  )
}