import React from 'react';
import Navbar from './Navbar';
import PresentationContainer from './PresentationContainer';
import MainBody from './MainBody';
import Footer from './Footer';

export default function LandingPage() {
  return(
    <>
      <Navbar />
      <PresentationContainer />
      <MainBody />
      <Footer />
    </>
  )
};
