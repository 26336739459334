import React from 'react';

export default function MainBody() {
  return (
    <React.Fragment>
      <div className="container pt80 xspt60" id="about-us">
        <div className="superstar-fam-wrapper text-center">
          <div className="superstar-content">
            <h1>Meet The Superstar Fam</h1>
            <h3>You will love to be friends with them!</h3>
            <p>
              TSF is a collection of 10102 Hand-drawn, Unique & Diverse artists,
              musicians, dancers, magicians, comedians and entertainers. They
              live on the Ethereum blockchain as ERC-721 tokens and are hosted
              on IPFS.
            </p>
            <p>
              With 300+ traits, including mouth, hair, eyes, clothes,
              instruments/props, and a random art generation process, TSF
              characters are highly distinguished and thoughtfully crafted
              Avatars.
            </p>
            <p>
              Every TSF NFT holder gets access to the La La Land show and VIP
              level perks for the same!
            </p>
          </div>
        </div>
        <div className="mt80">
          <div className="owl-carousel owl-carousel-6">
            <div>
              <img className="img-responsive" src="images/1.jpg" alt="" />
            </div>
            <div>
              <img className="img-responsive" src="images/2.jpg" alt="" />
            </div>
            <div>
              <img className="img-responsive" src="images/3.jpg" alt="" />
            </div>
            <div>
              <img className="img-responsive" src="images/4.jpg" alt="" />
            </div>
            <div>
              <img className="img-responsive" src="images/5.jpg" alt="" />
            </div>
            <div>
              <img className="img-responsive" src="images/6.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="la-la-land-show" className="container text-center pt80 xspt60">
        <h2>Welcome to the Promised Land</h2>
        <h3>An inviting space for chill events and fun live shows!</h3>
        <p className="mb0">
          Your TSF NFTs open doors for you to attend virtual events & live gigs
          at La La Land but it’s more than just a simple pass.
        </p>
        <p>
          TSF NFT owners also get to bring someone along to an event with a
          members-only event exclusive{" "}
          <span style={{ fontWeight: "bold" }}>“PlusOne Pass”</span> which can
          be claimed for free with every new event launch.
        </p>
      </div>
      <div className="container-fluid p0 mt40 xsmt40 text-center">
        <div>
          <img
            className="img-responsive"
            src="images/lalaland.png"
            alt="First slide"
          />
        </div>
      </div>
      <div className="container mt80 xsmt40">
        <h2 className="text-center mb0">Is that it? NOT AT ALL!</h2>
        <div className="flex not-at-all">
          <div className="card">
            <video autoPlay muted loop className="img-responsive">
              <source
                src="https://dla1e4xo0vb1n.cloudfront.net/external_media/plusoneanimation.mp4"
                type="video/mp4"
              />
            </video>
            <div className="card-body">
              <h3 className="text-center">PlusOne Pass NFT</h3>
              <p className="card-text">
                A PlusOne Pass is a privilege you get for being a TSF NFT Holder
                & supporter of talented & rising artists.
              </p>
              <p>
                This exclusive pass is an NFT that can be sold on the secondary
                market to anyone interested in attending an event or you could
                simply get your Plus One and enjoy the event together.
              </p>
              <p>
                This way you share the love for a rising superstar and also make
                money with your PlusOne Pass as an event promoter!
              </p>
            </div>
          </div>
          <div className="card">
            <img
              className="img-responsive card-img-top"
              src="images/signaturenft.jpeg"
              alt="Card cap"
            />
            <div className="card-body">
              <h3 className="text-center">Signature NFT</h3>
              <p className="card-text">
                For NFT owners, every show in La La Land comes with an amazing
                chance to own a limited edition, signature NFT signed by
                artists.
              </p>
              <p>
                Think of it as owning a precious insignia from a rare
                performance.
              </p>
              <p>
                Imagine if you’ve been supporting a future Drake or Dua Lipa
                since their earliest years and now think how valuable your
                prized possession can become if the artist makes it huge!
              </p>
              <p>HODL or SODL, the choice is yours!</p>
            </div>
          </div>
        </div>
        <h3 className="text-center" style={{ marginTop: 0 }}>
          In La La Land, it literally pays to be a Superstar fan!
        </h3>
        <div id="benefits" className="container pt80 xspt60">
          <div className="revolution-wrapper text-center">
            <h2 className="mb40">Ready To Join The Revolution?</h2>
            <div className="clearfix">
              <div className="col-md-3">
                <div className="cardWrapper">
                  <div className="card">
                    <h5 className="card-title">Creative Control</h5>
                    <p className="card-text">
                      Creative and commercial rights given to the NFT Holder
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="cardWrapper">
                  <div className="card">
                    <h5 className="card-title">Rewarding Holders</h5>
                    <p className="card-text">
                      Raffles, Contests, PlusOne Pass NFTs, Signature NFTs and more!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="cardWrapper">
                  <div className="card">
                    <h5 className="card-title">Future Proof</h5>
                    <p className="card-text">
                      Revenues earned are used to buy the floor, promote TSF,
                      and build the artist’s grants fund
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="cardWrapper">
                  <div className="card">
                    <h5 className="card-title">Governance</h5>
                    <p className="card-text">
                      One person, one vote approach for fair voting and unbiased
                      decision making
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="roadmap" className="container pt80 xspt60">
          <h2 className="text-center mb30 xsmt40">
            THE SHOW IS JUST GETTING STARTED
          </h2>
          <h3 className="text-center">
            Sale is live now!
            <br></br>
            Buy now at <span className="strikethrough">0.08 Ξ</span> <span style={{color: "#79e94c"}}>0.05 Ξ</span> only.
          </h3>
          <ul className="timeline">
            <li>
              <div className="direction-r">
                <div className="flag-wrapper">
                  <span className="flag">
                    10%
                    <br />- It’s raining Superstars: 10 reserved Superstar NFTs
                    are airdropped to random holders!
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-l">
                <div className="flag-wrapper">
                  <span className="flag">
                    20%
                    <br />- Community wallet gets initiated with 10 eth and gets
                    topped up with 5 eth at every 10% sales increment.
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-r">
                <div className="flag-wrapper">
                  <span className="flag">
                    30%
                    <br />- The first La La Land Show is organized
                    <br />- A rising music superstar takes the stage
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-l">
                <div className="flag-wrapper">
                  <span className="flag">
                    50%
                    <br />- Secret Companion NFT project is initiated
                    <br />
                    "Watch this space!"
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-r">
                <div className="flag-wrapper">
                  <span className="flag">
                    60%
                    <br />- Member-exclusive TSF Merch Store gets unlocked
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-l">
                <div className="flag-wrapper">
                  <span className="flag">
                    70%
                    <br />
                    <br />- The second La La Land Show goes live!
                    <br />- A rising comedian superstar takes the stage
                    <br />
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-r">
                <div className="flag-wrapper">
                  <span className="flag">
                    80%
                    <br />- Liquidity pool is set up
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-l">
                <div className="flag-wrapper">
                  <span className="flag">
                    90%
                    <br />- The Superstar Fam’s promised land of dreams opens
                    up: The La La Land will become a reality in the Metaverse
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="direction-r">
                <div className="flag-wrapper">
                  <span className="flag">
                    100%
                    <br />- The La La Land Show is officially launched as a DAO
                    that’s run and led by the community
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="container pt80 xspt60 xsmb40" id="faq">
        <h2 className="text-center mb40">Faqs</h2>
        <div className="faqs-wrapper">
          <div className="panel-group" id="accordion">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse1"
                  >
                    What is The Superstar Fam?
                  </a>
                </h4>
              </div>
              <div id="collapse1" className="panel-collapse collapse in">
                <div className="panel-body">
                  The Superstar Fam is a collection of 10102 unique Superstar
                  NFTs who live in La La Land on the ETH blockchain. Ownership
                  includes creative & commercial rights of your Superstars.
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse2"
                    aria-expanded="false"
                  >
                    Deets
                  </a>
                </h4>
              </div>
              <div id="collapse2" className="panel-collapse collapse">
                <div className="panel-body">
                  <ul>
                    <li>Release date: 3rd Oct'2021. Join our <a href="https://discord.gg/QRcSUZfMN8">discord</a> to stay updated.</li>
                    <li>No. of tokens: 10102 ERC-721 NFT tokens</li>
                    <li>Community Wallet contribution: 50 eth</li>
                    <li>
                      Storage: The artwork itself is stored on IPFS and we plan
                      to eternalize this on the permaweb via Arweave once all
                      10102 Superstars have been minted
                    </li>
                    <li>Cost: <span className="strikethrough">0.08 Ξ</span> 0.05 Ξ</li>
                    <li>
                      Max Mint: 20 Superstars at a time (via website or
                      etherscan)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse3"
                    aria-expanded="false"
                  >
                    How to get a Superstar?
                  </a>
                </h4>
              </div>
              <div id="collapse3" className="panel-collapse collapse">
                <div className="panel-body">
                  <ul>
                    <li>
                      Superstar will be available for purchase on our website
                      through an initial sale. At the time of purchase, a
                      randomly selected Superstar will be minted on the
                      blockchain and delivered to your wallet and OpenSea
                      account
                    </li>
                    <li>
                      Download the Metamask extension for Google Chrome. Buy
                      Ethereum (ETH) on an exchange like Coinbase, Binance,
                      PayPal, etc and send it to your Metamask ETH Wallet Public
                      Address with an ERC-20 transfer. Connect your Metamask
                      Wallet to our website by clicking on the Chrome/Brave
                      extension icon at the top-right of the browser (it looks
                      like a Fox) while on our website. Click “Buy Now” on our
                      website and approve the transaction on Metamask
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse4"
                    aria-expanded="false"
                  >
                    What’s the story behind The Superstar Fam?
                  </a>
                </h4>
              </div>
              <div id="collapse4" className="panel-collapse collapse">
                <div className="panel-body">
                  Go to our <a href="https://discord.gg/QRcSUZfMN8">discord</a>{" "}
                  and learn more about the story behind The Superstar Fam
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse5"
                    aria-expanded="false"
                  >
                    How were the Superstars created?
                  </a>
                </h4>
              </div>
              <div id="collapse5" className="panel-collapse collapse">
                <div className="panel-body">
                  Superstars are hand-drawn, inspired by creators, generated
                  algorithmically by combining 300+ properties with varying
                  rarities in six categories (Clothes, Backgrounds, Eyes,
                  Mouths, Props/Instruments, Hair). The mathematical
                  combinations are in millions but with only 10102 being minted,
                  some are more unique and rare than others
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse6"
                    aria-expanded="false"
                  >
                    Why were the Superstars created?
                  </a>
                </h4>
              </div>
              <div id="collapse6" className="panel-collapse collapse">
                <div className="panel-body">
                  The Superstar Fam is an ode to the story behind the journey of
                  creators from being dependent on local audiences to exhibit
                  their talent to being limitless through decentralised
                  organisations like The La La Land show
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse8"
                    aria-expanded="false"
                  >
                    How do I get a high-resolution/transparent/pfp version of my
                    Superstar?
                  </a>
                </h4>
              </div>
              <div id="collapse8" className="panel-collapse collapse">
                <div className="panel-body">
                  <ul>
                    <li>
                      Jump into our discord on the channel #bot-commands and use
                      the appropriate commands to get your choice of Superstar’s
                      specs
                    </li>
                    <li>
                      Go to our site, the section “My Superstars”, sign with
                      your Metamask and get all the choices and versions of
                      Superstar you want
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse9"
                    aria-expanded="false"
                  >
                    What is MOGO? (Mint One Get One)
                  </a>
                </h4>
              </div>
              <div id="collapse9" className="panel-collapse collapse">
                <div className="panel-body">
                  <p>
                    Everyone who mints before 12 October 2021 9:00 PM EST, gets to claim one free TSF NFT for every NFT they hold. This reduces the effective price to 0.04 ETH per NFT.
                  </p>
                  <p>
                    Points to Note:
                  </p>
                  <ul style={{"listStyle":"lower-alpha"}}>
                    <li>All existing TSF Holders are eligible.</li>
                    <li>Free mints will be available only to folks who have either minted through our website or purchased on the secondary market, giveaway winners will not be eligible.</li>
                    <li>We will take a snapshot of all holders on 12th October at 9:00 PM EST. Only those who are holding their NFTs till that time, will qualify to claim free NFTs.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse10"
                    aria-expanded="false"
                  >
                    New to NFTs? Minting your first one?
                  </a>
                </h4>
              </div>
              <div id="collapse10" className="panel-collapse collapse">
                <div className="panel-body">
                  Go to our <a href="https://discord.gg/QRcSUZfMN8">discord</a>{" "}
                  and learn more about minting for the first time
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt80 mb80 xsmt40 xsmb40" id="team">
        <h2 className="text-center mb40">The Team</h2>
        <div className="flex xlflexWrap xsJustifyCenter">
          <div className="team-card">
            <img className="img-responsive" src="images/1.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/aishakadon"
              >
                Don
              </a>
            </p>
          </div>
          <div className="team-card">
            <img className="img-responsive" src="images/sararara.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/sara_bharadwaj"
              >
                Sararara
              </a>
            </p>
          </div>
          <div className="team-card">
            <img className="img-responsive" src="images/seven.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/zankalp"
              >
                Seven
              </a>
            </p>
          </div>
          <div className="team-card">
            <img className="img-responsive" src="images/6.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/ramnika.sehrawat/"
              >
                Rum
              </a>
            </p>
          </div>
        </div>
        <div className="flex xlflexWrap xsJustifyCenter">
          <div className="team-card">
            <img className="img-responsive" src="images/3.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/jaspreet21anand/"
              >
                ParaSkiRun
              </a>
            </p>
          </div>
          <div className="team-card">
            <img className="img-responsive" src="images/4.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://www.behance.net/tushargaur1"
              >
                Tushyysushyy
              </a>
            </p>
          </div>
          <div className="team-card">
            <img className="img-responsive" src="images/6.jpg" alt="" />
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/freek1ll_"
              >
                Freek1ll
              </a>
            </p>
          </div>
          <div className="team-card">
            <video autoPlay muted loop className="img-responsive">
              <source src="images/Superstargif.mp4" type="video/mp4" />
            </video>
            <p className="m0 text-center">
              <a
                className="team-link"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/TheSuperstarFam"
              >
                You
              </a>
            </p>
          </div>
        </div>
      </div>
      <p className="text-center"><a target="_blank" style={{textDecoration: "underline", color: "white"}} href="https://etherscan.io/address/0x953F2E39174DebF031DC42D176513f6EE0a48A8C">Verified Contract : 0x953F2E39174DebF031DC42D176513f6EE0a48A8C</a></p>
    </React.Fragment>
  );
}
