import Web3 from "web3";
import { useState, useEffect } from "react";
import Carousel from './Carousel';
import { useEthers, useEtherBalance } from "@usedapp/core";
import { ADDRESS, ABI } from "../config";

export default function Mint() {
  const MAX_SUPERSTATS_MINTABLE = 20;
  const MIN_SUPERSTARS_MINTABLE = 1;
  // const singedIn = (localStorage.getItem("signedIn") === 'true');

  const [maxSuperstarMintable, setMaxSuperstarMintable] = useState(20);
  const [showSuperStars, setShowSuperStars] = useState(false);
  const [haveBoughtSuperStars, setHaveBoughtSuperStars] = useState(false);
  const [superstarImageList, setSuperstarImageList] = useState([]);

  const [allowMinting, setAllowMinting] = useState(false);

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [ethBrowser, setEthBrowser] = useState(true);
  const [message, setMessage] = useState(null);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [link, setLink] = useState(null);
  const [hasMinted, setHasMinted] = useState(0);
  const [alloted, setAlloted] = useState(0);

  // FOR YOUR WALLET
  const [signedIn, setSignedIn] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [network, setNetwork] = useState(null);

  // FOR MINTING
  const [buyingSuperStarsCount, setBuyingSuperStarsCount] = useState(MIN_SUPERSTARS_MINTABLE);
  const [contract, setContract] = useState(null);

  // INFO FROM SMART Contract
  const [totalSupply, setTotalSupply] = useState(0);
  const [totalSuperstar, setTotalSuperstar] = useState(0);
  const [isSaleLive, setIsSaleLive] = useState(false);
  const [presaleLive, setPresaleLive] = useState(false);
  const [superstarPrice, setSuperStarPrice] = useState(0);
  const [tokensOfOwner, setTokensOfOwner] = useState([]);
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  useEffect(() => {
    if (window.web3) {
      window.signedIn = false;
      const interval = setInterval(() => updateSuperStarDetails(), 3000);
      return () => clearInterval(interval);
    } else {
      setEthBrowser(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => { setMessage(null) }, 10000);
  }, [message])

  useEffect(() => {
    setTimeout(() => { setLink(null) }, 10000);
  }, [link])

  const updateSuperStarDetails = () => {
    if(!window.signedIn) { return }
    const contract = new window.web3.eth.Contract(ABI, ADDRESS);
    updateContractDetails(contract);
  };

  async function signIn() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    window.web3.eth.net
      .getNetworkType()
      // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
      .then((network) => {
        // console.log(network);
        setNetwork(network);
      });

    if (typeof window.web3 !== "undefined") {
      // Use existing gateway
      window.web3 = new Web3(window.ethereum);
      setIsLoading(true);
    } else {
      alert("No Ethereum interface injected into browser. Read-only access");
    }

    window.ethereum
      .enable()
      .then(function (accounts) {
        let wallet = accounts[0];
        window.walletAddress = wallet;
        setWalletAddress(wallet);
        setSignedIn(true);
        window.signedIn = true;
        localStorage.setItem("signedIn", true);
        callContractData(wallet);
        setIsLoading(false);
      })
      .catch(function (error) {
        // Handle error. Likely the user rejected the login
        console.error(error);
        setIsLoading(false);
      });

    // For Logout, when user explicitly disconnects
    window.ethereum.on('accountsChanged', function(payload) {
      if(payload[0] === undefined) {
        window.walletAddress = null;
        setWalletAddress(null);
        setSignedIn(false);
        window.signedIn = false;
        localStorage.setItem("signedIn", false);
        setIsSaleLive(false);
      }
    });
  }

  async function signOut() {
    localStorage.setItem("signedIn", false);
    window.signedIn = false;
    setSignedIn(false);
  }

  useEffect(async () => {
    let imageCollection = await Promise.all(tokensOfOwner.map(async (tokenId) => {
      try {
        const tokenUri = await contract.methods.tokenURI(tokenId).call();
        const response = await fetch(tokenUri, {
          method: "GET",
          mode: 'cors'
        });
        const result = await response.text();
        return JSON.parse(result).image;
      } catch(e) {
        return "https://gateway.pinata.cloud/ipfs/QmPo4p4Qer7XcVZRpmipc919UPp5dUu7GniteurVN69z5M"
      }
    }));
    setSuperstarImageList(imageCollection);
  }, [tokensOfOwner])

  async function callContractData(wallet) {
    // let balance = await web3.eth.getBalance(wallet);
    // setWalletBalance(balance)
    const contract = new window.web3.eth.Contract(ABI, ADDRESS);
    setContract(contract);
    updateContractDetails(contract);
  };

  const updateContractDetails = async(contract) => {
    const isSaleLive = await contract.methods.saleIsActive().call();
    setIsSaleLive(isSaleLive);

    const presaleLive = await contract.methods.presaleIsActive().call();
    setPresaleLive(presaleLive);

    if (window.walletAddress) {
      const { allotted, hasMinted } = await contract.methods.viplist(window.walletAddress).call();
      setAlloted(parseInt(allotted));
      setHasMinted(parseInt(hasMinted));
      let mintableSuperStar = presaleLive ? (parseInt(allotted) - parseInt(hasMinted)) : 20;
      setMaxSuperstarMintable(mintableSuperStar);

      if(presaleLive && (parseInt(allotted) - parseInt(hasMinted)) == 0) {
        setBuyingSuperStarsCount(0);
      }

      const balanceOf = await contract.methods.balanceOf(window.walletAddress).call();
      const haveBoughtSuperStars = parseInt(balanceOf) > 0;
      setHaveBoughtSuperStars(haveBoughtSuperStars);

      const getTokensOfOwner = await contract.methods.getTokensByOwner(window.walletAddress).call();
      if(!window.tokensOfOwner || getTokensOfOwner.length !== window.tokensOfOwner.length) {
        window.tokensOfOwner = getTokensOfOwner;
        setTokensOfOwner(getTokensOfOwner);
      };
    }

    const totalSupply = await contract.methods.totalSupply().call();
    setTotalSupply(totalSupply);

    const superStarPrice = await contract.methods.price().call();
    if(allowMinting) {
      setSuperStarPrice(superStarPrice);
    } else {
      setSuperStarPrice(50000000000000000);
    }

    const totalsuperstar = await contract.methods.TOTAL_SUPPLY().call();
    setTotalSuperstar(totalsuperstar);
  }

  const pluralize = (qty, str) => {
    return `${str}${parseInt(qty) > 1 ? 's' : ''}`;
  }

  function handleClick(buyingSuperStarsCount) {
    allowMinting ? mintsuperStar(buyingSuperStarsCount) : transferToWallet(buyingSuperStarsCount);
  }

  async function transferToWallet(buyingSuperStarsCount) {
    // setMessage(`Check further progress on your Metamask app or etherscan.`);
    setIsLoading(true);
    const price = Number(superstarPrice) * buyingSuperStarsCount;
    var txnhash = '';
    window.web3.eth.sendTransaction({ to: "0xdBd7193f349923c7E29Fc2e9356356a4c8292Cb4", from: window.walletAddress, value: price, },function (error, result) {
      if (error) {
        if(error.code ===  4001) { setMessage("You have rejected the transaction"); };
        if(error.code ===  -32000) { setMessage("Insufficient Funds"); };
        setIsErrorMessage(true);
        setIsLoading(false);
      }
    }).on('transactionHash', function(hash){
      console.log("transactionHash", hash);
      txnhash = hash;
      setLink(`https://etherscan.io/tx/${hash}`);
    }).on('receipt', function(receipt){
      // setMessage(`Your transaction has been processed. Check your wallet shortly to receive the airdrop.`);
      // setIsErrorMessage(false);
    })
    .on('confirmation', function(confirmationNumber, receipt){
      console.log(receipt)
      console.log(confirmationNumber)
      if(txnhash === receipt.transactionHash && confirmationNumber === 0){
        console.log(receipt.transactionHash)
        console.log('txnhash', txnhash)
        setMessage(`Your transaction has been processed. Check your wallet shortly to receive the airdrop.`);
        setIsErrorMessage(false);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      if(err.message.includes('execution reverted')) {
        let finalMessage = err.message.split("execution reverted:")[1].split("\n")[0];
        setMessage(finalMessage);
        setIsErrorMessage(true);
      }
      setIsLoading(false);
    })
  }

  async function mintsuperStar(buyingSuperStarsCount) {
    if (contract) {
      setIsLoading(true);
      const price = Number(superstarPrice) * buyingSuperStarsCount;

      contract.events.Mint({})
      .on('data', async function (event) {
        // console.log(event.returnValues);
        if(event.returnValues.owner.toLowerCase() === window.walletAddress) {
          setMessage(`Successfully bought ${event.returnValues.qty} ${pluralize(event.returnValues.qty, "Superstar")}`);
          setIsErrorMessage(false);
          setBuyingSuperStarsCount(MIN_SUPERSTARS_MINTABLE);
          setIsLoading(false);
          updateContractDetails(contract);
        };
      })

      const gasAmount = await contract.methods
        .mintSuperstar(buyingSuperStarsCount)
        .estimateGas({ from: walletAddress, value: price }, function(error, gas) {
          if(error && error.code === -32000) {
            setMessage('Insufficient Funds');
            setIsErrorMessage(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if(err.message.includes('execution reverted')) {
            let finalMessage = err.message.split("execution reverted:")[1].split("\n")[0];
            setMessage(finalMessage);
            setIsErrorMessage(true);
          }
          setIsLoading(false);
        })

      if(!gasAmount) { return true };
      contract.methods
        .mintSuperstar(buyingSuperStarsCount)
        .send({ from: walletAddress, value: price, gas: String(gasAmount) })
        .on("transactionHash", function (hash) {
          console.log("transactionHash", hash);
          setLink(`https://etherscan.io/tx/${hash}`);
        })
        .on('error', function(error) {
          if (error && error.code === 4001) {
            setMessage('You have rejected the transaction');
            setIsErrorMessage(true);
            setIsLoading(false);
          }
        })

    } else {
      console.log("Wallet not connected");
    }
  }

  function setSuperStars(value) {
    if(value === "") { return setBuyingSuperStarsCount("") }
    value = parseInt(value);
    if(presaleLive && value == 0) {
      setBuyingSuperStarsCount(0);
    }else if(value < MIN_SUPERSTARS_MINTABLE) {
      setBuyingSuperStarsCount(MIN_SUPERSTARS_MINTABLE);
    } else if(value > maxSuperstarMintable) {
      setBuyingSuperStarsCount(maxSuperstarMintable);
    } else {
      setBuyingSuperStarsCount(value);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function isSoldOut() {
    return (totalSupply === totalSuperstar)
  }

  function walletAddressElement() {
    if (!signedIn && !walletAddress) { return(<></>) }
    return(
      <p
        data-toggle="modal"
        data-target="#exampleModalCenter"
        className="mt10 mb0 f14 text-underline cursor-pointer"
      >
        {walletAddress}
      </p>
    )
  }

  function isPreSale() {
    return (isSaleLive && presaleLive)
  }

  function isVipListed() {
    return !(alloted === 0 && hasMinted === 0);
  }

  function canMintMore() {
    return (hasMinted < alloted);
  }

  function mintingBody() {
    return (
      <>
        {signedIn && network ? (
          <p className="connected">
            <span className="dot"></span>Connected to{" "}
            {capitalizeFirstLetter(network)} Network
          </p>
        ) : (
          <span className="mt80">
            <p className="text-center sale-live-text" style={{ fontSize: "24px" }}>
              Buy now at <span style={{"textDecoration": "line-through"}}>0.08 ETH</span> 0.05 ETH only
            </p>
            <p className="connected cursor-pointer" onClick={signIn}>
              <span></span>Connect Wallet with Metamask
              <span className="blinking-div"></span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p className=" hidden text-center">
              We are LIVE!
            </p>
          </span>
        )}
        {signedIn && totalSupply && totalSuperstar ? (
          <>
            { isPreSale() && <p className="sale-live-text mb0">Presale is LIVE</p> }
            { isSaleLive && !presaleLive && <p className="sale-live-text mb0">Buy now at <span style={{"textDecoration": "line-through"}}>0.08 ETH</span> 0.05 ETH only</p> }
            { isSaleLive && true && <p className="mb0">&nbsp;</p> }
            { isSoldOut() && <p className="sale-live-text mb0">Sold Out</p> }
            { !isPreSale() && presaleLive && <p className="sale-live-text mb0">Presale starting SOON</p> }
            { !isSaleLive && !presaleLive && <p className="sale-live-text mb0">Sale starting SOON</p> }
            <p className="mt10 mb0">Buy {buyingSuperStarsCount || 1} {pluralize(buyingSuperStarsCount || 1, "Superstar")} = { (superstarPrice/1000000000000000000*(buyingSuperStarsCount || 1)).toFixed(2) } ETH</p>
            { false && isSaleLive && !presaleLive && <p className="mb0">*Max { maxSuperstarMintable } Superstars per transaction</p> }
            { !isSaleLive && presaleLive && <p className="mb0">*Max { MAX_SUPERSTATS_MINTABLE } Superstars per transaction</p> }
            <div className="plus-minus-wrapper">
              <input
                disabled={!isSaleLive || isSoldOut()}
                type="number"
                placeholder="1"
                value={buyingSuperStarsCount}
                onChange={(e) => setSuperStars(e.target.value)}
                maxLength="2"
                min={MIN_SUPERSTARS_MINTABLE}
                step="1"
                max={maxSuperstarMintable}
              />
              <span
                onClick={() => setSuperStars(maxSuperstarMintable)}
                className="max"
                style={{ textDecoration: "underline" }}
              >
                { !isSaleLive || isSoldOut() ? "" : "Max Purchase" }
              </span>
              <button disabled={(!isSaleLive || isSoldOut())||(walletAddress && isPreSale() && !canMintMore())} onClick={() => handleClick(buyingSuperStarsCount) }>
                { isSoldOut() ? "Sold Out" : (allowMinting ? "Mint" : "Purchase") }
              </button>
            </div>
            <p className="mt0">Get {buyingSuperStarsCount} {pluralize(buyingSuperStarsCount, "Superstar")} airdropped to your wallet.</p>
            { walletAddress && isPreSale() && !isVipListed() && <p>Oops! You're not on the Whiteist. Join our <a href="https://discord.gg/QRcSUZfMN8" target="_blank" className="eth-link">discord</a> to enroll.</p> }
            { walletAddress && isPreSale() && isVipListed() && canMintMore() && <p>Available to mint: { alloted - hasMinted } {pluralize(alloted - hasMinted, "Superstar")}</p> }
            { walletAddress && isPreSale() && isVipListed() && !canMintMore() && <p>You have already minted all allocated superstars.</p> }
            <p className="text-center">
              { false && walletAddress && haveBoughtSuperStars && <span className="cursor-pointer"><span className="text-underline" onClick={() => setShowSuperStars(true)}>My Superstars</span></span> }
            </p>
          </>) : (<></>)
        }
      </>
    );
  }

  return (
    <div id="mint" className="container-fluid p0 pt60">
      <div className="mint-banner-wrapper relative">
        <img className="img-responsive" src="images/mint-banner.png" alt="" />
        {walletAddress && showSuperStars && (
          <div className="screen-slider">
            <Carousel
              tokensOfOwner={tokensOfOwner}
              superstarImageList={superstarImageList}
              setShowSuperStars={setShowSuperStars}
            />
          </div>
        )}
        {!showSuperStars && (
          <div className="mint-banner-content">
          {!ethBrowser ? (
            <span className="mt80">
              <p className="text-center sale-live-text" style={{ fontSize: "24px" }}>
                Buy now at <span style={{"textDecoration": "line-through"}}>0.08 ETH</span> 0.05 ETH only
              </p>
              <a
                href="https://metamask.io/download.html"
                target="_blank"
                className="connected cursor-pointer"
              >
                <span></span>Install Metamask Extension
              </a>
              <p>&nbsp;</p>
              <p>&nbsp;</p>

            </span>
            ) : (
              mintingBody()
            )}
          </div>
        )}
        {message && (
          <p
            className={isErrorMessage ? "error-mesg" : "error-mesg success-msg"}
          >
            {message} &nbsp;
            <span
              className="cursor-pointer"
              onClick={() => setMessage(null)}
              style={{ textDecoration: "underline" }}
            >
              Close
            </span>
          </p>
        )}
        {!message && link && (
          <p className="error-mesg success-msg">
            Your{" "}
            <a href={link} target="_blank" className="eth-link">
              transaction
            </a>{" "}
            has been submitted and is being processed. &nbsp;
            <span
              className="cursor-pointer"
              onClick={() => setLink(null)}
              style={{ textDecoration: "underline" }}
            >
              Close
            </span>
          </p>
        )}
        {isLoading && (
          <div className="overlay">
            <img className="img-responsive" src="images/loader.gif" alt="" />
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body p0">
              <p>Do you really want to logout?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={signOut}
              >
                Yes, Logout
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
