import React, { useEffect } from 'react';

export default function Carousel({ superstarImageList, setShowSuperStars, tokensOfOwner }) {
  useEffect(() => {
    window.$(".owl-carousel-2").owlCarousel({
      center: false,
      items: 2,
      loop: (superstarImageList.length === 1) ? false : true,
      margin: 30,
      nav: false,
      dots: true,
      autoplay: true,
      slideTransition: "linear",
      autoplayTimeout: 2000,
      autoplaySpeed: 2000,
      autoplayHoverPause: true,
      responsive: {
        0: { items: 1 },
        600: { items: 2 },
        1000: { items: 2 },
      },
    });
  });
  var tweets = [
    "Love the smell of freshly minted NFTs! Excited to be a part of this @TheSuperstarFam",
    "Curtains Open, The Show Begins! Pumped to be a part of this @TheSuperstarFam",
    "The stage is set and the show is ON! Stoked to be a part of this @TheSuperstarFam",
    "It’s official! I’m a part of the coolest NFT community! Watch this space for our adventures @TheSuperstarFam",
    "This HYPE is REAL! Love being a part of a cool NFT community @TheSuperstarFam",
    "I just minted some crazy awesome NFTs today! Thrilled to be a part of this @TheSuperstarFam",
    "Got me some amazing NFTs and I just can’t keep calm! Pumped to be a part of the promised land @TheSuperstarFam",
    "If dopeness had a look, it would be exactly like my new NFTs! Excited to join the future of entertainment @TheSuperstarFam",
    "Hyped up about my amazing unique superstar NFT and it’s dope! Can’t wait to see what’s next in store for the community @TheSuperstarFam",
    "Super cool NFTs just arrived in my wallet! Pumped to be a part of a revolution in making @TheSuperstarFam",
    "I’m a proud owner of a unique superstar NFT and it doesn’t get any better! Onwards and upwards @TheSuperstarFam",
    "Check out my cool new superstar NFT and tell me WDYT? All set to cruise in the metaverse with @TheSuperstarFam"
  ]

  return (
    <React.Fragment>
      <div className="clearfix">
        <div className="owl-carousel owl-carousel-2">
          { superstarImageList.map((imageURL, index) => {
            return(<div key={index} className="banner-slider-item">
              <img className="img-responsive" src={`${imageURL}`} alt="" />
              <p className="m0 text-center">
                <span className="tweet-superstar-span">
                  <a style={{color: "white", textDecoration: "underline"}} target="_blank" href={`${imageURL}`}>
                    download
                  </a>&nbsp;&nbsp;&nbsp;
                  <a className="tweet-superstar" href={`https://twitter.com/intent/tweet?hashtags=NFT,thesuperstarfam,mysuperstar&text=${tweets[parseInt((Math.random()*1000)%tweets.length)]}`} target="_blank">
                    <svg height="22px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="22px" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                      <path d="M492,109.5c-17.4,7.7-36,12.9-55.6,15.3c20-12,35.4-31,42.6-53.6c-18.7,11.1-39.4,19.2-61.5,23.5  C399.8,75.8,374.6,64,346.8,64c-53.5,0-96.8,43.4-96.8,96.9c0,7.6,0.8,15,2.5,22.1C172,179,100.6,140.4,52.9,81.7  c-8.3,14.3-13.1,31-13.1,48.7c0,33.6,17.1,63.3,43.1,80.7C67,210.7,52,206.3,39,199c0,0.4,0,0.8,0,1.2c0,47,33.4,86.1,77.7,95  c-8.1,2.2-16.7,3.4-25.5,3.4c-6.2,0-12.3-0.6-18.2-1.8c12.3,38.5,48.1,66.5,90.5,67.3c-33.1,26-74.9,41.5-120.3,41.5  c-7.8,0-15.5-0.5-23.1-1.4C62.9,432,113.8,448,168.4,448C346.6,448,444,300.3,444,172.2c0-4.2-0.1-8.4-0.3-12.5  C462.6,146,479,128.9,492,109.5z" />
                    </svg>
                  </a>
                </span>
              </p>
            </div>)
          })}
        </div>
        { false && <p onClick={() => setShowSuperStars(false)}>Add more to your collection</p> }
      </div>
    </React.Fragment>
  );
}
