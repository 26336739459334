import LandingPage from "./components/LandingPage";
import GiftPage from "./components/GiftPage";
import whitePaper from "./components/whitePaper";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App-header">
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/gift" component={GiftPage} />
          <Route exact path="/whitepaper" component={whitePaper} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
